import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyC1ajLRrH3ARHmdkiTEtAb0Lhq1DinAEA4',
  authDomain: 'i-ean-project.firebaseapp.com',
  projectId: 'i-ean-project',
  storageBucket: 'i-ean-project.appspot.com',
  messagingSenderId: '178167551282',
  appId: '1:178167551282:web:980a813872fb61c080332b',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
