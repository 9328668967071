











import { Component, Vue } from 'vue-property-decorator';

import AppBar from '@/components/structure/AppBar.vue';
import AppNavDrawer from '@/components/structure/AppNavDrawer.vue';
import { Getter } from 'vuex-class';
import WebConfTypes from '@/store/types/WebConfTypes';

@Component({
  components: {
    AppBar,
    AppNavDrawer,
  },
})
export default class Base extends Vue {}
