import { MutationTree, GetterTree, ActionTree } from 'vuex';

import { State } from '@/store/interfaces/User';
import UserTypes from '@/store/types/UserTypes';

const namespaced = true;

const state: State = {
  user: null,
};

const getters: GetterTree<State, any> = {
  [UserTypes.getters.GETUSER]: state => state.user,
  [UserTypes.getters.USERISLOGGED]: state => {
    if (state.user) return true;
    if (localStorage.getItem('user_firebase') !== null && localStorage.getItem('user_firebase') !== 'null') return true;
    return false;
  },
  [UserTypes.getters.USERTOKENFIREBASE]: async state => {
    if (state.user) return await state.user.getIdToken();
    else return localStorage.getItem('user_firebase_last_token');
  },
};

const mutations: MutationTree<State> = {
  [UserTypes.mutations.SETUSER]: (state, user) => {
    state.user = user;
  },
};

const actions: ActionTree<State, any> = {
  [UserTypes.actions.UPDATEUSER]: async ({ commit }, user: firebase.User) => {
    localStorage.setItem('user_firebase', JSON.stringify(user));
    if (user) localStorage.setItem('user_firebase_last_token', await user.getIdToken());
    console.log(localStorage.getItem('user_firebase_last_token'));
    commit(UserTypes.mutations.SETUSER, user);
  },
  [UserTypes.actions.LOGOUTUSER]: async ({ commit }) => {
    localStorage.removeItem('user_firebase');
    localStorage.removeItem('user_firebase_last_token');
    commit(UserTypes.mutations.SETUSER, null);
  },
};

//const stringifyToObject = (objectString: string): object => JSON.parse(objectString);

export const userAuthStore = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
