import { RouteConfig } from 'vue-router';

const DashboardRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "access" */ '../views/Home.vue'),
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import(/* webpackChunkName: "payments" */ '../views/payments/ListPayments.vue'),
  },
  {
    path: '/refunds',
    name: 'Refunds',
    component: () => import(/* webpackChunkName: "payments" */ '../views/payments/ListRefunds.vue'),
  },
  {
    path: '/payments/:payment_id',
    name: 'PaymentDetails',
    props: true,
    component: () => import(/* webpackChunkName: "payments_details" */ '../views/payments/Payment.vue'),
  },

  {
    path: '/customers',
    name: 'Customers',
    component: () => import(/* webpackChunkName: "customers" */ '../views/customers/ListCustomers.vue'),
  },
  {
    path: '/customers/:customer_id',
    name: 'CustomerProfile',
    props: true,
    component: () => import(/* webpackChunkName: "customers_profile" */ '../views/customers/CustomerProfile.vue'),
  },
  {
    path: '/accounting',
    name: 'Accounting',
    component: () => import(/* webpackChunkName: "accounting" */ '../views/accounting/Accounting.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/products/ListProducts.vue'),
  },
  {
    path: '/eans',
    name: 'Eans',
    component: () => import(/* webpackChunkName: "eans" */ '../views/eans/Eans.vue'),
  },
];

export default DashboardRoutes;
