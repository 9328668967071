






import { Component, Vue } from 'vue-property-decorator';
import { auth } from './plugins/firebase';

import { Action } from 'vuex-class';
import UserTypes from './store/types/UserTypes';

@Component({
  components: {},
})
export default class App extends Vue {
  @Action(`userAuthStore/${UserTypes.actions.UPDATEUSER}`) updateUserFirebase: any;

  blockScreen = false;

  beforeCreate() {
    auth.onAuthStateChanged(async user => {
      this.updateUserFirebase(user);
    });
  }
}
