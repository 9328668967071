
































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  props: {
    avatar: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: () => '',
    },
  },
})
export default class BaseMaterialCard extends Vue {
  @Prop({ default: () => '' })
  public title!: string;
  @Prop({ default: () => '' })
  public icon!: string;

  get classes() {
    return {
      'v-card--material--has-heading': this.hasHeading,
    };
  }
  get hasHeading() {
    return Boolean(this.$slots.heading || this.title || this.icon);
  }
  get hasAltHeading() {
    return Boolean(this.$slots.heading || (this.title && this.icon));
  }
}
