






import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import WebConfTypes from '@/store/types/WebConfTypes';
@Component
export default class AppBar extends Vue {
  @Action(`webConfigStore/${WebConfTypes.actions.UPDATESHOWNAVDRAWER}`) setShowNavDrawer!: void;
}
