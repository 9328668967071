export default {
  mutations: {
    SETUSER: 'SETUSER',
  },
  getters: {
    GETUSER: 'GETUSER',
    USERISLOGGED: 'USERISLOGGED',
    USERTOKENFIREBASE: 'USERTOKENFIREBASE',
  },
  actions: {
    UPDATEUSER: 'UPDATEUSER',
    LOGOUTUSER: 'LOGOUTUSER',
  },
};
