import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { State } from '../interfaces/WebConf';
import WebConfTypes from '../types/WebConfTypes';

const namespaced = true;

const state: State = {
  configuration: {
    showNavDrawer: true,
  },
};

const getters: GetterTree<State, any> = {
  [WebConfTypes.getters.GETSHOWNAVDRAWER]: state => {
    return state.configuration.showNavDrawer;
  },
};

const mutations: MutationTree<State> = {
  [WebConfTypes.mutations.SETSHOWNAVDRAWER]: (state, showNav: boolean) => {
    state.configuration.showNavDrawer = showNav;
  },
};

const actions: ActionTree<State, any> = {
  [WebConfTypes.actions.UPDATESHOWNAVDRAWER]: async ({ commit, state }) => {
    commit(WebConfTypes.mutations.SETSHOWNAVDRAWER, !state.configuration.showNavDrawer);
  },
};

export const webConfigStore = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
