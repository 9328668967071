export default {
  mutations: {
    SETSHOWNAVDRAWER: 'SETSHOWNAVDRAWER',
  },
  getters: {
    GETSHOWNAVDRAWER: 'GETSHOWNAVDRAWER',
  },
  actions: {
    UPDATESHOWNAVDRAWER: 'UPDATESHOWNAVDRAWER',
  },
};
